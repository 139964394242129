/** @jsx jsx */
import { jsx, Box, Flex, Grid, Heading, Text } from 'theme-ui'
import { graphql, useStaticQuery } from 'gatsby'
import slugify from 'underscore.string/slugify'
import React from 'react'

import { formatNumber } from 'utils'

import { FoodMenuNav, SEO } from 'components'

const MENUS_QUERY = graphql`
  query {
    menus: allContentfulMenuSection(
      sort: { fields: order, order: ASC }
      filter: { season: { eq: "summer-2020" } }
    ) {
      edges {
        node {
          ...ContentfulMenuSectionFragment
        }
      }
    }
  }
`

function FoodSymbol(props) {
  const { children } = props
  return (
    <Box
      sx={{
        borderRadius: 999,
        border: 'solid 1px',
        borderColor: 'black.20',
        fontSize: 12,
        textAlign: 'center',
        width: 28,
        height: 28,
        minWidth: 28,
        pt: '3px',
        mr: '5px',
      }}
    >
      <Text variant="stnd.sub" sx={{ fontWeight: 'heading' }}>
        {children}
      </Text>
    </Box>
  )
}

function MenuItem(props) {
  const { item } = props

  return (
    <Box>
      <Grid
        columns="auto 120px"
        sx={{ borderBottom: 'solid 1px', borderColor: 'black.10', pb: 'm' }}
      >
        <Box>
          <Heading variant="heading.s" sx={{ mb: 's', color: 'orange.dark' }}>
            {item.title}
          </Heading>

          {item?.ingredients?.text && (
            <Text mb="xs">{item?.ingredients?.text}</Text>
          )}
          <Flex sx={{ alignItems: 'center' }}>
            {item?.description?.text && (
              <Text variant="stnd.xxs" sx={{ color: 'black.60', mr: 'xs' }}>
                {item?.description?.text}
              </Text>
            )}
            <VeggieVegan {...item} />
          </Flex>
        </Box>
        <Box sx={{ textAlign: 'right' }}>
          <Flex sx={{ justifyContent: 'flex-end' }}>
            {item?.lowerCost && <Text>{formatNumber(item.lowerCost)}</Text>}

            {item?.upperCost && (
              <>
                <span sx={{ mx: 5 }}>/</span>
                <Text>{formatNumber(item.upperCost)}</Text>
              </>
            )}
          </Flex>
        </Box>
      </Grid>
      {item.subMenu && <SubMenu menu={item.subMenu} />}
    </Box>
  )
}

function VeggieVegan(props) {
  const { glutenFree, vegetarian, vegan } = props
  if (glutenFree || vegetarian || vegan) {
    return (
      <Flex>
        {glutenFree && <FoodSymbol>GF</FoodSymbol>}
        {vegetarian && <FoodSymbol>VG</FoodSymbol>}
        {vegan && <FoodSymbol>V</FoodSymbol>}
      </Flex>
    )
  }
  return null
}

function SubMenu(props) {
  const { menu } = props
  return (
    <Box pt={['m', null, 'l']}>
      <Heading variant="heading.s" sx={{ mb: 's', color: 'orange.dark' }}>
        {menu.title}
      </Heading>
      <Grid columns={[1, 2]} gap={'s'}>
        {menu.menuItems.map((item) => (
          <Box>
            <Box sx={{ fontWeight: 'heading', mb: 3 }}>{item.title}</Box>
            <Flex sx={{ alignItems: 'center' }}>
              {item?.lowerCost && (
                <Text mr="s">{formatNumber(item.lowerCost)}</Text>
              )}
              <VeggieVegan {...item} />
            </Flex>
          </Box>
        ))}
      </Grid>
    </Box>
  )
}

function CurrentMenu(props) {
  const { menu } = props

  return (
    <Box
      sx={{
        maxWidth: 780,
        px: ['m', null, 'l', 'xl'],
        pt: ['l', 'xl', 'xxl'],
        boxSizing: 'content-box',
      }}
      id={slugify(menu.title)}
    >
      <Box>
        <Heading
          variant="heading.l"
          sx={{ mb: menu.subhead ? 0 : ['m', null, 'l', 'xl'] }}
        >
          {menu.title}
        </Heading>

        {menu.subhead && (
          <Text variant="stnd.xxs" mb={['m', null, 'l']}>
            {menu.subhead}
          </Text>
        )}

        <Grid gap={['s', null, 'm']}>
          {menu.menuItems.map((item, key) => (
            <MenuItem key={key} item={item} />
          ))}
        </Grid>

        {menu.submenus && menu.submenus.map((menu) => <SubMenu menu={menu} />)}
      </Box>
    </Box>
  )
}

function FoodMenu() {
  const { menus } = useStaticQuery(MENUS_QUERY)

  return (
    <>
      <SEO title="Food Menu" />
      <Box variant="boxes.container" pb={['xl', 'xxl', 'xxxl']}>
        <Grid columns={[null, null, '300px auto', '370px auto']}>
          <FoodMenuNav options={menus.edges} />
          <Box>
            {menus.edges.map((menu, key) => (
              <CurrentMenu menu={menu?.node} key={key} />
            ))}
          </Box>
        </Grid>
      </Box>
    </>
  )
}

export const MenuItemFragment = graphql`
  fragment MenuItemFragment on ContentfulMenuItem {
    title
    lowerCost
    upperCost
    ingredients {
      text: ingredients
    }
    description {
      text: description
    }
    glutenFree
    vegan
    vegetarian
  }
`

export const SubMenuItemFragment = graphql`
  fragment SubmenuFragment on ContentfulSubmenu {
    title
    menuItems: menuItem {
      ...MenuItemFragment
    }
  }
`

export const ContentfulMenuSectionFragment = graphql`
  fragment ContentfulMenuSectionFragment on ContentfulMenuSection {
    title
    subhead
    order
    submenus {
      ...SubmenuFragment
    }
    menuItems {
      ...MenuItemFragment
      subMenu {
        ...SubmenuFragment
      }
    }
  }
`

export default FoodMenu
